

export const isDev = false
export function vuetifyConfiguration() {
  const options = {"defaults":{"VAppBar":{"elevation":0},"VSheet":{"elevation":10,"width":300,"class":"pa-4","rounded":"xl"},"VBtn":{"rounded":"xl","elevation":0},"VTextField":{"rounded":"xl","variant":"outlined","density":"compact","hideDetails":"auto"},"VCheckbox":{"rounded":"xl","hideDetails":"auto"},"VTextarea":{"rounded":"xl","variant":"outlined"},"VList":{},"V45":{},"VMenu":{},"VCard":{"rounded":"xl"},"VFab":{"rounded":"xl"},"VSelect":{"rounded":"xl"}},"theme":{"themes":{"light":{"colors":{"secondary":"#FFFFFF"}},"dark":{"colors":{"primary":"#FFFFFF","secondary":"#000000"}}}},"ssr":true}
  
  
  
  
  return options
}

